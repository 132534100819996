import { useState, useEffect } from "react";
import { User } from "../types/InsightTypes"; // Added import for User type

// Custom hook to fetch user data
export function useFetchUsers() {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("/organization/user");
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                setError(error as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { users, loading, error };
}
