import { useEffect } from "react";

interface MockApiResponseProps {
    setApiResponseReceived: (value: boolean) => void;
    // Ensure setMessageQueue is typed to accept a function that receives a string array and returns a string array
    setMessageQueue: (
        value: string[] | ((prevQueue: string[]) => string[]),
    ) => void;
    answersLength: number;
}

const MockApiResponse: React.FC<MockApiResponseProps> = ({
    setApiResponseReceived,
    setMessageQueue,
    answersLength,
}) => {
    useEffect(() => {
        const isLocalhost =
            window.location.hostname === "localhost" ||
            window.location.hostname === "127.0.0.1";

        if (!import.meta.env.DEV || !isLocalhost) {
            return;
        }

        const simulateApiResponse = () => {
            const sampleResponses = [
                "Hello, this is a simulated response from GPT-4. How can I assist you today?",
                "The typewriter effect can be visually appealing. It adds a dynamic touch to the chat interface.",
                "Simulating variable response lengths and delays can provide a more realistic experience.",
                "Remember to handle errors and edge cases in your actual API integration.",
                "This is a longer simulated response to demonstrate how the typewriter effect handles more extensive text.",
                `Hej! Att uppdatera Ubuntu är lite annorlunda än att uppdatera Windows, men processen är fortfarande ganska enkel.\n\n` +
                    `För att uppdatera Ubuntu manuellt, kan du öppna terminalen och skriva följande kommandon:\n\n` +
                    `För att uppdatera listan över tillgängliga paket och deras versioner, skriv: sudo apt update\n` +
                    `För att faktiskt installera de tillgängliga uppdateringarna, skriv: sudo apt upgrade\n\n` +
                    `Dessa kommandon kommer att be om ditt lösenord och sedan börja ladda ner och installera uppdateringar.\n\n` +
                    `Ubuntu har också en mjukvaruuppdaterare som du kan använda för att uppdatera systemet. Du kan öppna den från appmenyn, och om det finns uppdateringar tillgängliga, kommer den att meddela dig och ge dig möjlighet att installera dem.\n\n` +
                    `För att automatiskt uppdatera Ubuntu, kan du konfigurera 'Unattended Upgrades'. Detta är ett paket som kan installeras och konfigureras för att automatiskt installera säkerhetsuppdateringar. Du kan också konfigurera det för att installera andra typer av uppdateringar om du vill.`,
                // PowerShell code example
                "Here's a PowerShell command to list all services running on your system:\n\n```powershell\nGet-Service | Where-Object {$_.Status -eq 'Running'}Get-Service | Where-Object {$_.Status -eq 'Running'}Get-Service | Where-Object {$_.Status -eq 'Running'}Get-Service | Where-Object {$_.Status -eq 'Running'}Get-Service | Where-Object {$_.Status -eq 'Running'}Get-Service | Where-Object {$_.Status -eq 'Running'}Get-Service | Where-Object {$_.Status -eq 'Running'}Get-Service | Where-Object {$_.Status -eq 'Running'}\n```",
            ];

            const simulatedMessage =
                sampleResponses[
                    Math.floor(Math.random() * sampleResponses.length)
                ];
            const delay =
                simulatedMessage.length * 10 + Math.floor(Math.random() * 500);

            setTimeout(() => {
                setApiResponseReceived(true);
                // Correctly type the prevQueue parameter and ensure the function returns a string array
                setMessageQueue((prevQueue) => [
                    ...prevQueue,
                    simulatedMessage,
                ]);
            }, delay);
        };

        simulateApiResponse();
    }, [answersLength, setApiResponseReceived, setMessageQueue]);

    return null;
};

export default MockApiResponse;
