import React, { useEffect, useState, lazy, Suspense } from "react";
import { useFetchUsers } from "../../hooks/useFetchUsers";
import useAuth from "../../hooks/useAuth"; // Import the useAuth hook
import styles from "./Users.module.css";
import { debounce } from "lodash";

const UserDetails = lazy(() => import("./UserDetails"));

interface UsersProps {
    isSidebarMinimized: boolean;
}

const Users: React.FC<UsersProps> = ({ isSidebarMinimized }) => {
    useAuth(); // Use the authentication hook to check auth status and redirect if not authenticated

    const { users, loading, error } = useFetchUsers();
    const [visibleEntries, setVisibleEntries] = useState(20);

    const loadMoreUsers = () => {
        setVisibleEntries((prevVisibleEntries) => prevVisibleEntries + 20);
    };

    useEffect(() => {
        const handleScroll = debounce(() => {
            if (
                window.innerHeight + document.documentElement.scrollTop !==
                document.documentElement.offsetHeight
            )
                return;
            loadMoreUsers();
        }, 200);

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            handleScroll.cancel();
        };
    }, []);

    return (
        <div
            className={`${styles["users-container"]} ${isSidebarMinimized ? styles["users-container-expanded"] : ""}`}
        >
            {loading ? (
                <div className={styles["loading-spinner"]}></div>
            ) : error ? (
                <div>Error loading users.</div>
            ) : (
                users.slice(0, visibleEntries).map((user) => (
                    <Suspense
                        key={user.id}
                        fallback={
                            <div className={styles["loading-spinner"]}></div>
                        }
                    >
                        <div className={styles["user-card"]}>
                            <UserDetails user={user} />
                        </div>
                    </Suspense>
                ))
            )}
        </div>
    );
};

export default Users;
