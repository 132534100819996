import { ConversationItem, FormattedConversation } from "../../api/models";

export function getTimeAgoInNanoseconds(
    numberOfUnits: number,
    timeScale: string,
) {
    const nanoseconds: any = {
        nanoseconds: 1,
        microseconds: 1000,
        milliseconds: 1000 * 1000,
        seconds: 1000 * 1000 * 1000,
        minutes: 1000 * 1000 * 1000 * 60,
        hours: 1000 * 1000 * 1000 * 60 * 60,
        days: 1000 * 1000 * 1000 * 60 * 60 * 24,
        weeks: 1000 * 1000 * 1000 * 60 * 60 * 24 * 7,
        // Note: Approximations for months and years, as their lengths vary
        months: 1000 * 1000 * 1000 * 60 * 60 * 24 * 30,
        years: 1000 * 1000 * 1000 * 60 * 60 * 24 * 365,
    };

    if (!nanoseconds[timeScale]) {
        throw new Error(
            "Invalid time scale. Please use nanoseconds, microseconds, milliseconds, seconds, minutes, hours, days, weeks, months, or years.",
        );
    }

    const currentTimeInMilliseconds = new Date().getTime();
    const pastTimeInMilliseconds = new Date(
        currentTimeInMilliseconds -
            numberOfUnits * (nanoseconds[timeScale] / 1000000),
    );
    return pastTimeInMilliseconds.getTime() * 1000000; // Convert to nanoseconds
}

export function convertToGPTHistoryFormat(
    data: ConversationItem[],
): FormattedConversation {
    // Group by conversation_id
    const grouped: { [key: string]: ConversationItem[] } = data.reduce(
        (acc: any, item) => {
            acc[item.conversation_id] = acc[item.conversation_id] || [];
            acc[item.conversation_id].push(item);
            return acc;
        },
        {},
    );

    // Sort by timestamp and format each conversation
    return Object.keys(grouped).reduce((acc: any, conversationId) => {
        const sortedConversation = grouped[conversationId].sort(
            (a, b) => a.timestamp - b.timestamp,
        );
        acc[conversationId] = {};

        sortedConversation.forEach((message, messageIndex) => {
            if (messageIndex % 2 === 0) {
                acc[conversationId][messageIndex] = {
                    content: message.question_content,
                    role: "user",
                };
            } else {
                acc[conversationId][messageIndex] = {
                    content: message.answer_content,
                    role: "assistant",
                };
            }
        });

        return acc;
    }, {});
}

export async function getConversation(id: string) {
    const backendUrl =
        "/user/conversation?uuid=true&" +
        new URLSearchParams({
            id: id,
        });

    // Use the token in the request to the backend
    const response = await fetch(backendUrl, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    return JSON.parse(await response.text());
}
