import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./Sidebar.module.css";
import { getTimeAgoInNanoseconds, getConversation } from "./SidebarHelpers";
import { ChatMessage, ChatProps } from "../../api";
import {
    FaAngleDoubleLeft,
    FaAngleDoubleRight,
    FaEdit,
    FaTrash,
} from "react-icons/fa";
import logo from "../../assets/LOGO-TRANS.ico";
import ThemeToggle from "../ThemeToggle"; // Importing the ThemeToggle component
import Swal from "sweetalert2";

function getFromLocalStorage(key: string): any {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
}

function saveToLocalStorage(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
}

interface Conversation {
    conversation_id: string;
    title: string;
    timestamp: number;
    unique_user_name: string;
    lastActiveDate: number; // Added to track the last active date of each conversation
}

declare global {
    interface Window {
        gtag: any;
    }
}

const Sidebar: React.FC<
    ChatProps & {
        minimized: boolean;
        setMinimized: React.Dispatch<React.SetStateAction<boolean>>;
        resetChatState: () => void;
    }
> = ({
    answers,
    setAnswers,
    update = false,
    setUpdate,
    minimized,
    setMinimized,
    convoID,
    setConvoID,
    resetChatState,
}) => {
    const [activeTab, setActiveTab] = useState("chats");
    const [conversationHistory, setConversationHistory] = useState<
        { date: string; conversations: Conversation[] }[]
    >(
        process.env.NODE_ENV === "development"
            ? [
                  {
                      date: "2023-10-04",
                      conversations: [
                          {
                              conversation_id: "test1",
                              title: "Test Conversation 1",
                              timestamp: 1664851200000,
                              unique_user_name: "testuser1",
                              lastActiveDate: 1664851200000,
                          },
                          {
                              conversation_id: "test2",
                              title: "Test Conversation 2",
                              timestamp: 1664937600000,
                              unique_user_name: "testuser2",
                              lastActiveDate: 1664937600000,
                          },
                      ],
                  },
                  {
                      date: "2023-10-05",
                      conversations: [
                          {
                              conversation_id: "test3",
                              title: "Test Conversation 3",
                              timestamp: 1665024000000,
                              unique_user_name: "testuser3",
                              lastActiveDate: 1665024000000,
                          },
                      ],
                  },
              ]
            : [],
    );
    const [editingTitleId, setEditingTitleId] = useState<string | null>(null);
    const [editedTitle, setEditedTitle] = useState<string>("");
    const [selectedConversationId, setSelectedConversationId] = useState<
        string | null
    >(convoID);
    const navigate = useNavigate();

    // Pre-fetching and caching conversation data
    const [cachedConversations, setCachedConversations] = useState<
        Record<string, ChatMessage[]>
    >({});
    const conversationListRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleResize = () => {
            if (!localStorage.getItem("sidebarManualToggle")) {
                setMinimized(window.innerWidth < 1500);
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setMinimized]);

    useEffect(() => {
        const minimizedState = getFromLocalStorage("sidebarMinimized");
        if (minimizedState !== null) {
            setMinimized(minimizedState === "true");
        }

        const handleResize = () => {
            if (!localStorage.getItem("sidebarManualToggle")) {
                const isMinimized = window.innerWidth < 1500;
                setMinimized(isMinimized);
                saveToLocalStorage("sidebarMinimized", isMinimized.toString());
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setMinimized]);

    useEffect(() => {
        if (window.location.hash === "/chat") {
            fetchConversationHistory();
        }
    }, []);

    useEffect(() => {
        fetchConversationHistory();
        setSelectedConversationId(convoID);
    }, [convoID]);

    useEffect(() => {
        if (convoID) {
            setSelectedConversationId(convoID);
        }
    }, [convoID, conversationHistory]);

    useEffect(() => {
        fetchConversationHistory();
        const interval = setInterval(fetchConversationHistory, 30000);
        return () => clearInterval(interval);
    }, []);

    const fetchConversationHistory = async () => {
        try {
            const backendUrl =
                "/user/conversation?titles=true&" +
                new URLSearchParams({
                    from: getTimeAgoInNanoseconds(2, "weeks").toString(),
                    to: (Date.now() * 1000000).toString(),
                });

            const response = await fetch(backendUrl, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const text = await response.text();
            if (response.ok) {
                try {
                    const history = JSON.parse(text);
                    const uniqueConversations: any[] = Array.from(history).sort(
                        (a: any, b: any) => b.timestamp - a.timestamp,
                    );

                    const customTitles =
                        getFromLocalStorage("customTitles") || {};
                    uniqueConversations.forEach((conversation) => {
                        if (customTitles[conversation.conversation_id]) {
                            conversation.title =
                                customTitles[conversation.conversation_id];
                        }
                        // Retrieve last active date from local storage or set it to the current timestamp
                        conversation.lastActiveDate =
                            getFromLocalStorage(
                                `lastActiveDate-${conversation.conversation_id}`,
                            ) || conversation.timestamp;
                        saveToLocalStorage(
                            `lastActiveDate-${conversation.conversation_id}`,
                            conversation.lastActiveDate,
                        );
                    });

                    const allHiddenConversations =
                        getFromLocalStorage("hiddenConversations") || [];
                    const visibleConversations = uniqueConversations.filter(
                        (conversation) =>
                            !allHiddenConversations.includes(
                                conversation.conversation_id,
                            ),
                    );

                    // Grouping conversations by date
                    const groupedByDate = visibleConversations.reduce(
                        (acc, conversation) => {
                            const date = new Date(
                                conversation.lastActiveDate / 1000000, // Convert nanoseconds to milliseconds
                            ).toLocaleDateString();
                            if (!acc[date]) {
                                acc[date] = [];
                            }
                            acc[date].push(conversation);
                            return acc;
                        },
                        {},
                    );

                    // Convert grouped object back to array format for state
                    const groupedConversations = Object.keys(groupedByDate).map(
                        (date) => ({
                            date,
                            conversations: groupedByDate[date],
                        }),
                    );

                    setConversationHistory(groupedConversations);
                } catch (error) {
                    console.error("Error parsing conversation history:", error);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getTabClass = (tabName: string) => {
        return activeTab === tabName
            ? `${styles.tabButton} ${styles.activeTabButton}`
            : `${styles.tabButton}`;
    };

    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
        if (tabName === "chats") {
            navigate("/chat");
        }
        window.gtag("event", "navigate", {
            event_category: "Tab",
            event_label: tabName,
        });
    };

    const handleNewChatClick = () => {
        resetChatState();
        window.gtag("event", "click", {
            event_category: "Button",
            event_label: "New Chat",
        });
    };

    const handleConversationClick = (conversation: Conversation) => {
        // Check if conversation data is already cached
        const cachedData = cachedConversations[conversation.conversation_id];
        if (cachedData) {
            setAnswers([...cachedData]);
        } else {
            getConversation(conversation.conversation_id).then((res) => {
                setAnswers([...res]);
                // Cache the fetched conversation data
                setCachedConversations({
                    ...cachedConversations,
                    [conversation.conversation_id]: res,
                });
            });
        }
        setConvoID(conversation.conversation_id);
        setUpdate(true);
        setSelectedConversationId(conversation.conversation_id);
        window.gtag("event", "select_conversation", {
            event_category: "Conversation",
            event_label: conversation.conversation_id,
        });
        // Update last active date in local storage when a conversation is clicked
        saveToLocalStorage(
            `lastActiveDate-${conversation.conversation_id}`,
            Date.now() * 1000000, // Save timestamp in nanoseconds
        );
    };

    const toggleSidebar = () => {
        const newMinimizedState = !minimized;
        setMinimized(newMinimizedState);
        saveToLocalStorage("sidebarMinimized", newMinimizedState.toString());
        if (newMinimizedState) {
            localStorage.setItem("sidebarManualToggle", "true");
        } else {
            localStorage.removeItem("sidebarManualToggle");
        }
        window.gtag("event", "toggle_sidebar", {
            event_category: "Button",
            event_label: newMinimizedState ? "Expand" : "Minimize",
        });
    };

    const handleEditClick = (
        event: React.MouseEvent,
        conversationId: string,
        currentTitle: string,
    ) => {
        event.stopPropagation();
        setEditingTitleId(conversationId);
        setEditedTitle(currentTitle);
        window.gtag("event", "edit_conversation", {
            event_category: "Button",
            event_label: conversationId,
        });
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedTitle(event.target.value);
    };

    const handleTitleSave = (conversationId: string) => {
        const customTitles = getFromLocalStorage("customTitles") || {};
        customTitles[conversationId] = editedTitle;
        saveToLocalStorage("customTitles", customTitles);

        const updatedConversations = conversationHistory.map((group) => {
            return {
                date: group.date,
                conversations: group.conversations.map((conversation) => {
                    if (conversation.conversation_id === conversationId) {
                        return { ...conversation, title: editedTitle };
                    }
                    return conversation;
                }),
            };
        });

        setConversationHistory(updatedConversations);
        setEditingTitleId(null);
        window.gtag("event", "save_title", {
            event_category: "Button",
            event_label: conversationId,
        });
    };

    const handleRemoveConversation = (
        event: React.MouseEvent,
        conversationId: string,
    ) => {
        event.stopPropagation();
        const hiddenConversations =
            getFromLocalStorage("hiddenConversations") || [];
        if (!hiddenConversations.includes(conversationId)) {
            hiddenConversations.push(conversationId);
            saveToLocalStorage("hiddenConversations", hiddenConversations);
        }
        // Optimistically remove the conversation from the UI
        const newConversationHistory = conversationHistory
            .map((group) => ({
                date: group.date,
                conversations: group.conversations.filter(
                    (convo) => convo.conversation_id !== conversationId,
                ),
            }))
            .filter((group) => group.conversations.length > 0);
        setConversationHistory(newConversationHistory);

        // Simulate a delay for the deletion process
        setTimeout(() => {
            // Simulate a failure condition for demonstration
            const failureCondition = false; // Change this to true to simulate a failure
            if (failureCondition) {
                // Revert the optimistic update if deletion fails
                setConversationHistory(conversationHistory);
                // Display error message similar to Chat.tsx
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Failed to delete the conversation!",
                });
            }
        }, 2000);

        window.gtag("event", "remove_conversation", {
            event_category: "Button",
            event_label: conversationId,
        });
    };

    const handleKeyPress = (
        event: React.KeyboardEvent,
        conversationId: string,
    ) => {
        if (event.key === "Enter") {
            handleTitleSave(conversationId);
        }
    };

    function getButtonPosition(
        minimized: boolean,
        screenWidth: number,
    ): string | undefined {
        if (screenWidth < 1500) {
            return minimized ? undefined : "-480px";
        }
        return minimized ? "-30px" : undefined;
    }

    return (
        <div className={styles.sidebarContainer}>
            {!minimized && (
                <nav className={styles.sidebar}>
                    <div
                        className={styles.sidebarHeader}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <img
                            src={logo}
                            alt="Elyxir AI Logo"
                            className={styles.logo}
                            loading="lazy"
                        />
                        <span style={{ marginLeft: "10px", fontSize: "0.6em" }}>
                            Elyxir
                        </span>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.tabsContainer}>
                        <button
                            className={getTabClass("chats")}
                            onClick={() => handleTabClick("chats")}
                        >
                            Chats
                        </button>
                        <button
                            className={getTabClass("statistics")}
                            onClick={() => handleTabClick("statistics")}
                        >
                            Statistics
                        </button>
                        <div
                            className={`${styles.tabHighlight} ${activeTab === "chats" ? styles.chats : styles.statistics}`}
                        ></div>
                    </div>
                    <div
                        className={styles.newChatButton}
                        onClick={handleNewChatClick}
                    >
                        New Chat
                    </div>
                    <div className={styles.divider}></div>
                    {activeTab === "chats" && (
                        <div
                            className={styles.conversationHistory}
                            ref={conversationListRef}
                        >
                            <div className={styles["history-section"]}>
                                {conversationHistory.map((group) => (
                                    <>
                                        <div className={styles.dateHeader}>
                                            {group.date}
                                        </div>
                                        {group.conversations.map(
                                            (conversation: Conversation) => (
                                                <div
                                                    key={
                                                        conversation.conversation_id
                                                    }
                                                    className={`${styles["history-item"]} ${selectedConversationId === conversation.conversation_id ? styles.selectedConversation : ""}`}
                                                    onClick={() =>
                                                        handleConversationClick(
                                                            conversation,
                                                        )
                                                    }
                                                >
                                                    {editingTitleId ===
                                                    conversation.conversation_id ? (
                                                        <input
                                                            type="text"
                                                            value={editedTitle}
                                                            onChange={
                                                                handleTitleChange
                                                            }
                                                            onBlur={() =>
                                                                handleTitleSave(
                                                                    conversation.conversation_id,
                                                                )
                                                            }
                                                            onKeyDown={(e) =>
                                                                handleKeyPress(
                                                                    e,
                                                                    conversation.conversation_id,
                                                                )
                                                            }
                                                            autoFocus
                                                        />
                                                    ) : (
                                                        <>
                                                            <span
                                                                className={`${styles.singleLine}`}
                                                            >
                                                                {typeof conversation.title ===
                                                                "string"
                                                                    ? conversation
                                                                          .title
                                                                          .length <
                                                                      40
                                                                        ? conversation.title
                                                                        : conversation.title.substring(
                                                                              0,
                                                                              40,
                                                                          ) +
                                                                          "..."
                                                                    : ""}
                                                            </span>
                                                            <div
                                                                className={
                                                                    styles.actionIcons
                                                                }
                                                            >
                                                                <FaEdit
                                                                    onClick={(
                                                                        e,
                                                                    ) =>
                                                                        handleEditClick(
                                                                            e,
                                                                            conversation.conversation_id,
                                                                            conversation.title,
                                                                        )
                                                                    }
                                                                    className={
                                                                        styles.hoverIcon
                                                                    }
                                                                />
                                                                <FaTrash
                                                                    onClick={(
                                                                        e,
                                                                    ) =>
                                                                        handleRemoveConversation(
                                                                            e,
                                                                            conversation.conversation_id,
                                                                        )
                                                                    }
                                                                    className={
                                                                        styles.hoverIcon
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ),
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    )}
                    {activeTab === "statistics" && (
                        <ul className={styles.sidebarList}>
                            <li>
                                <NavLink
                                    to="/users"
                                    className={styles.tabButton}
                                >
                                    <span className="material-icons-sharp">
                                        group
                                    </span>{" "}
                                    Users
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/insight"
                                    className={styles.tabButton}
                                >
                                    <span className="material-icons-sharp">
                                        insights
                                    </span>{" "}
                                    Insight
                                </NavLink>
                            </li>
                        </ul>
                    )}
                    <div
                        className={styles.staticLinks}
                        style={{
                            marginTop: "auto",
                            fontSize: "0.8em",
                            position: "absolute",
                            bottom: "0",
                            display: "flex", // Changed to flex to align icons side by side
                            justifyContent: "center", // Center the icons horizontally
                        }}
                    >
                        {/* Removed the divider */}
                        <NavLink to="/privacy" className={styles.staticLink}>
                            <span className="material-icons-sharp">
                                privacy_tip
                            </span>
                        </NavLink>
                        <NavLink
                            to="/terms-of-service"
                            className={styles.staticLink}
                        >
                            <span className="material-icons-sharp">gavel</span>
                        </NavLink>
                        {/* Replacing the ThemeToggle component with an icon */}
                        <div className={styles.themeToggleIcon}>
                            <ThemeToggle />
                        </div>
                    </div>
                </nav>
            )}
            <button
                className={styles.minimizeButton}
                onClick={toggleSidebar}
                style={{
                    position: "absolute",
                    top: "50%",
                    right: getButtonPosition(minimized, window.innerWidth),
                    transform: "translateY(-50%)",
                    zIndex: 100,
                    opacity: 0.7,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                }}
            >
                {minimized ? (
                    <FaAngleDoubleRight style={{ fontSize: "20px" }} />
                ) : (
                    <FaAngleDoubleLeft style={{ fontSize: "20px" }} />
                )}
            </button>
        </div>
    );
};

export default Sidebar;
