import { useState, useEffect } from "react";

// Custom hook to fetch history data
export function useFetchHistory() {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("/user/token_use");
                const data = await response.json();
                setHistory(data);
            } catch (error) {
                setError(error as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { history, loading, error };
}
