import React from 'react';

function PrivacyPolicy() {
  return (
    <div style={{ textAlign: 'left', maxWidth: '800px', margin: '0 auto', padding: '0 20px' }}>
      <h1 style={{ textAlign: 'center', width: '100%' }}>Privacy Policy for Elyxir</h1>
      <p>Welcome to Elyxir, your premier AI chat service. At Elyxir, we are committed to protecting your privacy and ensuring a transparent understanding of how we collect, use, and safeguard your personal information. This Privacy Policy outlines our practices concerning the data we collect from users who access our service via integration with Microsoft 365.</p>
      
      <h2>Information We Collect</h2>
      <p>Upon using Elyxir, we may collect the following information:</p>
      <ul>
        <li>Personal Identification Information: Name, last name, and location, as provided through your Microsoft 365 account.</li>
        <li>Usage Data: Information on how often you sign in, which buttons you click on our website, and your navigation paths, collected via Google Analytics.</li>
        <li>Chat Logs: Conversations held within our service.</li>
        <li>Cookies and Tracking: We use cookies to track user activities and preferences for the purpose of improving our website and user experience.</li>
      </ul>
      
      <h2>How We Collect Your Data</h2>
      <p>Your data is collected through:</p>
      <ul>
        <li>Microsoft 365 Integration: When you log in with your Microsoft 365 account.</li>
        <li>Google Analytics: To analyze website usage and interactions.</li>
        <li>Cookies: Deployed to understand how you interact with our website.</li>
      </ul>
      
      <h2>Use of Your Data</h2>
      <p>Elyxir uses your data to:</p>
      <ul>
        <li>Enhance and personalize your user experience.</li>
        <li>Improve our website and services.</li>
        <li>Analyze chat logs for AI training purposes, aiming to enhance our AI's performance and response quality.</li>
      </ul>
      <p>We assure you that we do not sell or share your data with third parties, except as required by Swedish law.</p>
      
      <h2>Data Protection and Security</h2>
      <p>We employ AES-256 encryption and adhere to HTTPS protocols to ensure the highest level of security for your data. Our services, hosted on Azure, follow industry best practices for data protection and security. All data is stored within Sweden.</p>
      
      <h2>Your Rights</h2>
      <p>You have the right to request deletion of your data at any time. Should you wish to exercise this right, please contact us at support@dental-it.se with your name, email, and company affiliation.</p>
      
      <h2>Cookies and Tracking Technology</h2>
      <p>Elyxir uses cookies to enhance your experience on our website. Our cookie banner provides you with information and the opportunity to understand more about our cookie practices.</p>
      
      <h2>Children’s Privacy</h2>
      <p>Our service does not target nor knowingly collect information from individuals under the age of consent.</p>
      
      <h2>Changes to This Privacy Policy</h2>
      <p>We reserve the right to update or change our Privacy Policy at any time. You are advised to review this Privacy Policy periodically for any changes.</p>
      
      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at support@dental-it.se.</p>
    </div>
  );
}

export default PrivacyPolicy;
