import { useState, useEffect } from "react";
import { fetchUserData } from "./useFetchUserData";

// Custom hook to fetch token usage data
function useFetchTokenUsage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // Adjusting the error state to accept both null and Error types
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await fetchUserData("token_use");
        if (result.error) {
          throw new Error(result.message);
        }
        // Sorting the data based on date and timestamp before setting it to the state
        result.sort((a: any, b: any) => {
          const dateTimeA = new Date(`${a.date}T${a.timestamp}`);
          const dateTimeB = new Date(`${b.date}T${b.timestamp}`);
          return dateTimeB.getTime() - dateTimeA.getTime();
        });
        setData(result);
      } catch (error) {
        // Directly setting the caught error which is of type Error
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
}

export default useFetchTokenUsage;
