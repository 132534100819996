import React, { useEffect, useState, useRef } from "react";
import chatStyles from "../../pages/chat/Chat.module.css";
import aiAvatar from "../../assets/LOGO-TRANS-mini.svg";
import { marked } from "marked";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css"; // Importing PrismJS theme
import "prismjs/components/prism-powershell"; // Importing PrismJS PowerShell language component
import "prismjs/components/prism-sql"; // Importing PrismJS SQL language component
import "prismjs/components/prism-python"; // Importing PrismJS Python language component
import "prismjs/components/prism-typescript"; // Importing PrismJS TypeScript language component
import "prismjs/components/prism-css"; // Importing PrismJS CSS language component
import "prismjs/components/prism-javascript"; // Importing PrismJS JavaScript language component
import "prismjs/components/prism-bash"; // Importing PrismJS Bash language component

interface TypewriterEffectProps {
    messageQueue: string[];
    onTypingComplete: (message: string) => void;
    onTypingStart?: () => void; // Notify when typing starts
    onCharacterTyped?: () => void; // Notify when a character is typed
    dataRate: number; // Adjust typing speed based on data stream
    isDataStreaming: boolean; // Indicate if data is still being streamed
}

const TypewriterEffect: React.FC<TypewriterEffectProps> = React.memo(
    ({
        messageQueue,
        onTypingComplete,
        onTypingStart,
        onCharacterTyped,
        dataRate,
        isDataStreaming,
    }) => {
        const [typingState, setTypingState] = useState({
            currentMessage: "",
            charIndex: 0,
            queueIndex: 0,
        });
        const isContinuation = useRef(false);
        const isWaitingForMoreData = useRef(false);
        const [imageLoaded, setImageLoaded] = useState(false); // New state to track image loading

        useEffect(() => {
            // Preload aiAvatar image on component mount
            const aiAvatarImage = new Image();
            aiAvatarImage.src = aiAvatar;
            aiAvatarImage.onload = () => {
                console.log("Image loaded successfully"); // Add logging for successful image load
                setImageLoaded(true); // Set imageLoaded to true when image is loaded
            };
            aiAvatarImage.onerror = () => {
                console.error("Error loading image"); // Add logging for image load error
            };
        }, []);

        useEffect(() => {
            if (
                isDataStreaming &&
                typingState.queueIndex >= messageQueue.length - 1 &&
                typingState.charIndex >=
                    messageQueue[typingState.queueIndex]?.length
            ) {
                isWaitingForMoreData.current = true;
                return;
            }

            if (
                isWaitingForMoreData.current &&
                typingState.queueIndex < messageQueue.length
            ) {
                isWaitingForMoreData.current = false;
            }

            if (
                !isWaitingForMoreData.current &&
                typingState.queueIndex < messageQueue.length &&
                imageLoaded // Check if the image is loaded before starting typing
            ) {
                const currentQueueMessage =
                    messageQueue[typingState.queueIndex];
                const isNextMessageContinuation =
                    typingState.queueIndex > 0 &&
                    currentQueueMessage.startsWith(
                        messageQueue[typingState.queueIndex - 1],
                    );
                isContinuation.current = isNextMessageContinuation;

                if (
                    typingState.charIndex === 0 &&
                    onTypingStart &&
                    !isContinuation.current
                ) {
                    setTimeout(() => {
                        onTypingStart();
                    }, 1000);
                }
                if (typingState.charIndex < currentQueueMessage.length) {
                    // Optimized typing speed logic for consistency
                    const baseSpeed = 50; // Base speed set to a constant value
                    const speedAdjustmentFactor = 0.5; // Adjust the speed less aggressively
                    const speedAdjustment = Math.max(
                        1,
                        dataRate * speedAdjustmentFactor,
                    );
                    const finalSpeed = baseSpeed - speedAdjustment;

                    const handleTyping = () => {
                        const nextCharIndex = Math.min(
                            typingState.charIndex + 5,
                            currentQueueMessage.length,
                        ); // Batch updates by 5 characters
                        const nextPart = currentQueueMessage.slice(
                            typingState.charIndex,
                            nextCharIndex,
                        );
                        setTypingState((prevState) => ({
                            ...prevState,
                            currentMessage: prevState.currentMessage + nextPart,
                            charIndex: nextCharIndex,
                        }));
                    };

                    const id = requestAnimationFrame(handleTyping);
                    return () => cancelAnimationFrame(id);
                } else {
                    onTypingComplete(currentQueueMessage);
                    if (
                        isDataStreaming &&
                        typingState.queueIndex + 1 < messageQueue.length
                    ) {
                        setTypingState((prevState) => ({
                            ...prevState,
                            queueIndex: prevState.queueIndex + 1,
                            currentMessage: "",
                            charIndex: 0,
                        }));
                    } else if (
                        !isDataStreaming &&
                        typingState.queueIndex === messageQueue.length - 1
                    ) {
                        // No additional action needed for now
                    }
                    isContinuation.current = false;
                    const messageContainer = document.querySelector(
                        `.${chatStyles.chatMessageGpt}`,
                    );
                    if (messageContainer) {
                        messageContainer.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                        });
                    }
                }
            }
        }, [
            typingState.charIndex,
            typingState.queueIndex,
            messageQueue,
            onTypingComplete,
            onTypingStart,
            onCharacterTyped,
            dataRate,
            isDataStreaming,
            imageLoaded, // Added imageLoaded to dependencies
        ]);

        useEffect(() => {
            Prism.highlightAll();
        }, [typingState.currentMessage]);

        return (
            <>
                {typingState.currentMessage && (
                    <div
                        className={`${chatStyles.chatMessageGpt} typewriter-text`}
                        tabIndex={0}
                        aria-live="polite"
                    >
                        <img
                            src={aiAvatar}
                            className={chatStyles.chatAvatarAI}
                            alt="AI"
                        />
                        <div>
                            <strong className={chatStyles.chatMessageLabel}>
                                Elyxir
                            </strong>
                            <div className={chatStyles.chatMessageUserMessage}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: marked(
                                            typingState.currentMessage,
                                        ),
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.messageQueue === nextProps.messageQueue &&
            prevProps.dataRate === nextProps.dataRate &&
            prevProps.isDataStreaming === nextProps.isDataStreaming
        );
    },
);

export default React.memo(TypewriterEffect);
