import React, { useEffect, useState } from "react";
import styles from "./Login.module.css";
import backgroundImage from "../../assets/background.jpg"; // Add your background image here
import microsoftLogo from "../../assets/microsoft-logo.png";
import { useNavigate } from "react-router-dom";

function LoginPage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const response = await fetch("/user/auth");
                if (response.status === 200) {
                    navigate("/chat");
                }
            } catch (error) {
                console.error("Authentication check failed:", error);
            }
        };

        checkAuthStatus();
    }, [navigate]);

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768);
        }

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div
            className={styles.loginContainer}
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className={styles.loginButtonContainer}>
                <a href="/login_ms" className={styles.loginButton}>
                    <img
                        src={microsoftLogo}
                        alt="Microsoft Logo"
                        className={styles.microsoftLogoInsideButton}
                    />
                    Logga in med Microsoft
                </a>
            </div>
        </div>
    );
}

export default LoginPage;
