import React, { useEffect, useState, useMemo } from "react";
import { useMsal } from "@azure/msal-react";
import styles from "./Insight.module.css";
import {
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    Line,
} from "recharts";
import { useFetchUsers } from "../../hooks/useFetchUsers";
import { useFetchHistory } from "../../hooks/useFetchHistory";
import useFetchTokenUsage from "../../hooks/useFetchTokenUsage";
import { User, HistoryEntry } from "../../types/InsightTypes";
import useAuth from "../../hooks/useAuth"; // Import the useAuth hook

const Insight: React.FC<{ isSidebarMinimized: boolean }> = ({
    isSidebarMinimized,
}) => {
    useAuth(); // Use the authentication hook to check auth status and redirect if not authenticated

    const { users, loading: usersLoading, error: usersError } = useFetchUsers();
    const {
        history,
        loading: historyLoading,
        error: historyError,
    } = useFetchHistory();
    const {
        data: tokenUsageData,
        loading: dataLoading,
        error: tokenUsageDataError,
    } = useFetchTokenUsage();
    const [topActiveDates, setTopActiveDates] = useState<HistoryEntry[]>([]);
    const { accounts } = useMsal();

    const [totalTokens, setTotalTokens] = useState(0);
    const [aggregatedHistoryArray, setAggregatedHistoryArray] = useState<
        HistoryEntry[]
    >([]);
    const [visibleEntries, setVisibleEntries] = useState(10);

    useEffect(() => {
        if (!historyLoading && !historyError) {
            console.log("Received history data:", history); // Logging the received history data
            console.log(
                "Raw history data in JSON format:",
                JSON.stringify(history),
            ); // Logging the raw API data in JSON format
            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(endDate.getDate() - 29);

            let aggregatedHistory: { [date: string]: HistoryEntry } = {};
            for (
                let d = new Date(startDate);
                d <= endDate;
                d.setDate(d.getDate() + 1)
            ) {
                const formattedDate = d.toISOString().split("T")[0];
                aggregatedHistory[formattedDate] = {
                    date: formattedDate,
                    tokens: 0,
                    id: "",
                    timestamp: "",
                };
            }

            history.forEach((entry: HistoryEntry) => {
                const entryDate = entry.date;
                if (aggregatedHistory.hasOwnProperty(entryDate)) {
                    aggregatedHistory[entryDate].tokens += entry.tokens;
                    aggregatedHistory[entryDate].id = entry.id;
                    aggregatedHistory[entryDate].timestamp = entry.timestamp;
                }
            });

            let aggregatedHistoryArray: HistoryEntry[] =
                Object.values(aggregatedHistory);
            aggregatedHistoryArray.sort(
                (a: HistoryEntry, b: HistoryEntry) =>
                    new Date(a.date + "T" + a.timestamp).getTime() -
                    new Date(b.date + "T" + b.timestamp).getTime(),
            );

            let topAggregatedHistoryArray = [...aggregatedHistoryArray]
                .sort((a, b) => b.tokens - a.tokens)
                .slice(0, 5);

            setTopActiveDates(topAggregatedHistoryArray);

            const now = new Date();
            const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
            const tokensUsedLast24Hours = history
                .filter((entry: HistoryEntry) => {
                    const entryDate = new Date(
                        `${entry.date}T${entry.timestamp}`,
                    );
                    return entryDate >= oneDayAgo;
                })
                .reduce(
                    (acc: number, entry: HistoryEntry) => acc + entry.tokens,
                    0,
                );

            console.log(
                "Tokens used in the last 24 hours:",
                tokensUsedLast24Hours,
            );
            setTotalTokens(tokensUsedLast24Hours);
            setAggregatedHistoryArray(aggregatedHistoryArray);
        }
    }, [history, historyLoading, historyError]);

    const memoizedUserSection = useMemo(() => {
        return users
            .sort((a, b) => b.questions - a.questions)
            .slice(0, 5)
            .map((user: User) => (
                <div key={user.id} className={styles["user-row"]}>
                    <span className={styles["user-name"]}>
                        {user.firstName} {user.lastName}
                    </span>
                    <span className={styles["user-conversations"]}>
                        {user.questions}
                    </span>
                </div>
            ));
    }, [users]);

    return (
        <div
            className={`${styles["insight-container"]} ${isSidebarMinimized ? styles["sidebar-minimized"] : ""}`}
        >
            {usersLoading || historyLoading || dataLoading ? (
                <div className={styles["loading-spinner"]}></div>
            ) : usersError || historyError || tokenUsageDataError ? (
                <div>Error loading data. Please try again later.</div>
            ) : (
                <div
                    className={`${styles["sections-container"]} ${!dataLoading ? styles["data-loaded"] : ""} ${isSidebarMinimized ? styles["sidebar-minimized"] : ""}`}
                >
                    <div
                        className={`${styles["users-section"]} ${styles["calling-card"]}`}
                    >
                        <h1 className={styles["section-header"]}>
                            Organisations Most Active Users
                        </h1>
                        <div className={styles["column"]}>
                            <div className={styles["column-header"]}>
                                <span className={styles["column-title"]}>
                                    User
                                </span>
                                <span className={styles["column-title"]}>
                                    Messages Sent
                                </span>
                            </div>
                            {memoizedUserSection}
                        </div>
                    </div>
                    <div
                        className={`${styles["users-section"]} ${styles["calling-card"]}`}
                    >
                        <h1 className={styles["section-header"]}>
                            Organisations Most Active Dates
                        </h1>
                        <div className={styles["column"]}>
                            <div className={styles["column-header"]}>
                                <span className={styles["column-title"]}>
                                    Date
                                </span>
                                <span className={styles["column-title"]}>
                                    Tokens Used
                                </span>
                            </div>
                            {topActiveDates.map((entry: HistoryEntry) => (
                                <div
                                    key={entry.id}
                                    className={styles["user-row"]}
                                >
                                    <span className={styles["user-name"]}>
                                        {entry.date}
                                    </span>
                                    <span
                                        className={styles["user-conversations"]}
                                    >
                                        {entry.tokens}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className={`${styles["chart-full-width-section"]} ${styles["calling-card"]} ${isSidebarMinimized ? styles["sidebar-minimized"] : ""}`}
                    >
                        <div className={styles["chart-section"]}>
                            <h1 className={styles["section-header"]}>
                                My Token Usage Per Day Last 30 Days
                            </h1>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    data={aggregatedHistoryArray}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line
                                        type="monotone"
                                        dataKey="tokens"
                                        stroke="#8884d8"
                                        activeDot={{ r: 8 }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div
                        className={`${styles["history-section"]} ${styles["calling-card"]}`}
                    >
                        <h1 className={styles["section-header"]}>
                            Conversation Logs
                        </h1>
                        <div className={styles["column"]}>
                            <div className={styles["column-header"]}>
                                <span className={styles["column-title"]}>
                                    Date
                                </span>
                                <span className={styles["column-title"]}>
                                    Timestamp
                                </span>
                                <span className={styles["column-title"]}>
                                    ID
                                </span>
                                <span className={styles["column-title"]}>
                                    Tokens
                                </span>
                            </div>
                            {tokenUsageData
                                .slice(0, visibleEntries)
                                .map((entry: any) => (
                                    <div
                                        key={entry.id}
                                        className={styles["history-row"]}
                                    >
                                        <span
                                            className={styles["history-date"]}
                                        >
                                            {entry.date}
                                        </span>
                                        <span
                                            className={
                                                styles["history-timestamp"]
                                            }
                                        >
                                            {entry.timestamp}
                                        </span>
                                        <span className={styles["history-id"]}>
                                            {entry.id}
                                        </span>
                                        <span
                                            className={styles["history-tokens"]}
                                        >
                                            {entry.tokens}
                                        </span>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Insight;
