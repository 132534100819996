import React from 'react';

function TermsOfService() {
  return (
    <div style={{ textAlign: 'left', maxWidth: '800px', margin: '0 auto', padding: '0 20px' }}>
      <h1 style={{ textAlign: 'center', width: '100%' }}>Terms of Service for Elyxir</h1>
      <p>Welcome to Elyxir. These Terms of Service ("Terms") govern your use of the Elyxir chat service ("Service"), provided by Dental IT i Sverige AB ("we", "us", "our"). By accessing or using our Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you do not have permission to access the Service.</p>
      <h2>Service Description</h2>
      <p>Elyxir is an AI-powered chatbot designed to help companies gain insights into their data and employee inquiries. It integrates with Azure for user registration, pulling necessary information such as name, company, titles, and pictures upon approval within the tenant.</p>
      <h2>User Account and Registration</h2>
      <p>Registration is completed through an Azure application. Upon being allowed in the tenant, we collect information about users including their name, company, titles, and pictures. You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account.</p>
      <h2>Prohibited Activities</h2>
      <p>The use of Elyxir for illegal activities, harassment, or any malicious activities is strictly prohibited. We have policies in place to prevent the use of the Service for asking illegal questions. Violation of these policies may result in immediate termination of your account.</p>
      <h2>Content Ownership</h2>
      <p>You retain ownership of any content generated through the use of Elyxir. We claim no intellectual property rights over the material you provide to the Service.</p>
      <h2>Service Use and Limitations</h2>
      <p>You are allowed to use the Service as long as you comply with these Terms and do not abuse the Service. We reserve the right to modify, suspend, or terminate the Service or your access to the Service for any reason, without notice, at any time.</p>
      <h2>Payments and Subscriptions</h2>
      <p>Services are billed on a subscription basis. We offer two subscription models: one incorporating GPT-4 with the capability to upload your company data, and a less expensive option using GPT-3.5 Turbo without personalized data integration. Payments are made via agreements with Dental IT i Sverige AB. Subscriptions can be cancelled at any time, but the current month will be billed if a new cycle has started. We offer no refunds.</p>
      <h2>Liability and Disclaimer</h2>
      <p>We disclaim all responsibility for any outcomes resulting from the use of Elyxir. As you own the data and the content generated, you are responsible for how you use the prompts and replies provided by Elyxir.</p>
      <h2>Dispute Resolution</h2>
      <p>Any disputes arising out of or related to these Terms will be governed by Swedish law, without regard to its conflict of law provisions.</p>
      <h2>Amendments to These Terms</h2>
      <p>We reserve the right to amend these Terms at any time. Your continued use of the Service following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>
      <h2>Contact Information</h2>
      <p>For any questions about these Terms, please contact us at support@dental-it.se.</p>
      <h2>Acceptance of Terms</h2>
      <p>By using Elyxir, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p>
    </div>
  );
}

export default TermsOfService;
