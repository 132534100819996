import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Bypass authentication if running on localhost or in development environment
        if (
            window.location.hostname === "localhost" ||
            process.env.NODE_ENV === "development"
        ) {
            console.log("Bypassing authentication for local development.");
            return;
        }

        const checkAuth = async () => {
            try {
                const response = await fetch("/user/auth", {
                    credentials: "include", // Ensure cookies are sent
                });
                if (!response.ok) {
                    console.error(
                        "Authentication check failed with status:",
                        response.status,
                    );
                    navigate("/"); // Redirect to login page
                }
                const data = await response.json();
                if (!data.success) {
                    navigate("/"); // Redirect to login page
                }
            } catch (error) {
                console.error("Error during authentication check:", error);
                navigate("/"); // Redirect to login page on error
            }
        };

        checkAuth();
    }, []); // Empty dependency array to run only once on mount
};

export default useAuth;
