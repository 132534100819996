async function fetchUserData(dataType: string) {
    const baseUrl = "/user";
    const url = `${baseUrl}/${dataType}`;

    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
        });

        console.log("Received response", response);

        if (!response.ok) {
            console.error("Failed to fetch data from:", url);
            // Gracefully handle fetch errors
            return {
                error: true,
                message: `Failed to fetch data. Status: ${response.status}`,
            };
        }

        const data = await response.json();
        console.log("Parsed data:", data);

        return data;
    } catch (error) {
        console.error("Fetch error:", error);
        // Return a structured error object in case of failure
        return { error: true, message: "Failed to fetch data." };
    }
}

async function fetchUserPhoto() {
    try {
        const response = await fetch("/user/photo", {
            method: "GET",
        });

        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);

        console.log("User photo fetched successfully");
        return URL.createObjectURL(await response.blob());
    } catch (error) {
        console.error("Error fetching user photo:", error);
        return null;
    }
}

export { fetchUserData, fetchUserPhoto };
