import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import "react-toastify/dist/ReactToastify.css";
import CookieConsent from "react-cookie-consent";
import Swal from "sweetalert2";

import "./index.css";

import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import Users from "./pages/users/Users";
import Insight from "./pages/insight/Insight";
import Logout from "./pages/logout/Logout";
import Sidebar from "./components/Sidebar/Sidebar";
import PrivacyPolicy from "./pages/privacy/PrivacyPolicy";
import TermsOfService from "./pages/terms/TermsOfService";
import LoginPage from "./pages/login/Login";
import { ChatMessage, fetchSystemMessages } from "./api";

initializeIcons();

export default function App() {
    const [conversation, setConversation] = useState<ChatMessage[]>([]);
    const [update, setUpdate] = useState(false);
    const [minimized, setMinimized] = useState(false);
    const [convoID, setConvoID] = useState<string>(crypto.randomUUID());

    useEffect(() => {
        const fetchAndDisplayMessages = async () => {
            const response = await fetchSystemMessages();
            if (response.ok) {
                const messages = await response.json();
                messages.forEach(
                    (message: { header: string; body: string }) => {
                        // This section previously contained toast notifications
                    },
                );
            }
        };
        fetchAndDisplayMessages();
    }, []);

    const resetChatState = () => {
        setConversation([]);
        setConvoID(crypto.randomUUID());
    };

    const renderWithSidebar = (Component: React.FC<any>) => (
        <>
            <Sidebar
                answers={conversation}
                setAnswers={setConversation}
                convoID={convoID}
                setConvoID={setConvoID}
                update={update}
                setUpdate={setUpdate}
                minimized={minimized}
                setMinimized={setMinimized}
                resetChatState={resetChatState}
            />
            <Component
                answers={conversation}
                setAnswers={setConversation}
                convoID={convoID}
                setConvoID={setConvoID}
                update={update}
                setUpdate={setUpdate}
                isSidebarMinimized={minimized}
            />
        </>
    );

    const isMobileDevice = () => {
        const userAgent = navigator.userAgent;
        const mobileRegex =
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return mobileRegex.test(userAgent);
    };

    useEffect(() => {
        if (isMobileDevice()) {
            Swal.fire({
                title: "Warning",
                text: "This website has not been fully tested on mobile devices and may not function as expected.",
                icon: "warning",
                confirmButtonText: "I understand",
            });
        }
    }, []);

    return (
        <Router>
            <div style={{ display: "flex" }}>
                <Routes>
                    <Route path="/chat" element={renderWithSidebar(Chat)} />
                    <Route
                        path="/chat/:chatID"
                        element={renderWithSidebar(Chat)}
                    />
                    <Route path="users" element={renderWithSidebar(Users)} />
                    <Route
                        path="insight"
                        element={renderWithSidebar(Insight)}
                    />
                    <Route path="logout" element={renderWithSidebar(Logout)} />
                    <Route path="privacy" element={<PrivacyPolicy />} />
                    <Route
                        path="terms-of-service"
                        element={<TermsOfService />}
                    />
                    <Route path="/" element={<LoginPage />} />
                    <Route path="*" element={<NoPage />} />
                </Routes>
            </div>
            <CookieConsent
                location="bottom"
                buttonText="I understand"
                cookieName="elyxirCookieConsent"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience and to
                analyze performance and traffic on our website. We also share
                information about your use of our site with our social media,
                advertising, and analytics partners.
            </CookieConsent>
        </Router>
    );
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
