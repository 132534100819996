import React, { useState, useEffect } from "react";

const ThemeToggle: React.FC = () => {
    // Initial theme is set based on system preference, but can be overridden by user preference in localStorage
    const getInitialTheme = () => {
        const storedTheme = localStorage.getItem("theme");
        if (storedTheme) {
            return storedTheme;
        }
        const systemPreference = window.matchMedia(
            "(prefers-color-scheme: dark)",
        ).matches
            ? "dark"
            : "light";
        return systemPreference;
    };

    const [theme, setTheme] = useState(getInitialTheme);

    // Toggle theme and save preference to localStorage
    const toggleTheme = () => {
        const newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);
        localStorage.setItem("theme", newTheme);
        applyTheme(newTheme);
    };

    // Function to convert hex to RGBA
    function hexToRGBA(hex: string, opacity: number) {
        let r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    // Define the type for theme variables
    type ThemeVars = {
        [key: string]: string;
    };

    // Apply theme by setting class on body and updating CSS variables
    const applyTheme = (theme: string) => {
        document.body.className = theme;
        const root = document.documentElement;
        const themeVars = theme === "dark" ? darkThemeVars : lightThemeVars;
        Object.keys(themeVars).forEach((key) => {
            root.style.setProperty(key, themeVars[key]);
            if (key === "--text-color") {
                // Set semi-transparent text color
                const semiTransparentTextColor = hexToRGBA(themeVars[key], 0.5);
                root.style.setProperty(
                    "--semi-transparent-text-color",
                    semiTransparentTextColor,
                );
            }
        });
    };

    // Define theme variables for dark and light themes
    const darkThemeVars: ThemeVars = {
        "--background-color": "var(--background-dark)",
        "--text-color": "var(--text-dark)",
        "--input-color": "var(--input-dark)",
        "--button-bg-color": "var(--button-bg-dark)",
        "--button-text-color": "var(--button-text-dark)",
        "--sidebar-color": "var(--customTextField-dark)",
        "--customTextField-color": "var(--customTextField-dark)",
        "--highlight-color": "var(--highlight-color-dark)",
        "--spinner-border-color": "var(--spinner-border-dark)",
    };

    const lightThemeVars: ThemeVars = {
        "--background-color": "var(--background-light)",
        "--text-color": "var(--text-light)",
        "--input-color": "var(--input-light)",
        "--button-bg-color": "var(--button-bg-light)",
        "--button-text-color": "var(--button-text-light)",
        "--sidebar-color": "var(--customTextField-light)",
        "--customTextField-color": "var(--customTextField-light)",
        "--highlight-color": "var(--highlight-color-light)",
        "--spinner-border-color": "var(--spinner-border-light)",
    };

    // Apply theme on mount and when theme changes
    useEffect(() => {
        applyTheme(theme);
    }, [theme]);

    return (
        <button
            onClick={toggleTheme}
            style={{
                background: "transparent",
                border: "none",
                padding: "6px 45px 6px 45px",
                textDecoration: "none",
                color: "var(--text-color)",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "calc(100% - 30px)",
                boxSizing: "border-box",
                margin: "10px 15px",
                marginTop: "0px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
        >
            <span
                className="material-icons-sharp"
                style={{ color: "var(--text-color)" }}
            >
                {theme === "light" ? "light_mode" : "dark_mode"}
            </span>
        </button>
    );
};

export default ThemeToggle;
